import AzureADLogo from 'Images/brands/azure-ad.svg'
import ConfluenceLogo from 'Images/brands/confluence.svg'
import GuruLogo from 'Images/brands/guru.svg'
import HappyFoxLogo from 'Images/brands/happyfox.svg'
import HfServiceDeskLogo from 'Images/brands/hf-servicedesk.svg'
import SharePointLogo from 'Images/brands/microsoft-sharepoint.svg'
import NotionLogo from 'Images/brands/notion.svg'
import OktaLogo from 'Images/brands/okta.svg'
import OutlineLogo from 'Images/brands/outline.svg'
import SimpplrLogo from 'Images/brands/simpplr.svg'
import OtsImage from 'Images/ots.svg'

import KB_LINKS from 'Constants/kb-links'
import keyMap from 'Constants/key-map'

import { APP_CATEGORY } from './app-category'

const {
  SHAREPOINT,
  HAPPYFOX_HELPDESK,
  HAPPYFOX_SERVICE_DESK,
  NOTION,
  SIMPPLR,
  CONFLUENCE,
  GURU,
  OTHER_TICKETING_SYSTEM,
  OKTA,
  AZURE_AD,
  OUTLINE
} = keyMap

/*
  In the `apps` array below, `key`(in snack_case) is the value sent from BE and
  `id`(in kebab-case) is the value used for FE operations.
*/
const apps = [
  {
    key: SHAREPOINT.KEY,
    id: SHAREPOINT.ID,
    category: APP_CATEGORY.KNOWLEDGE_SOURCES,
    title: 'SharePoint',
    description:
      'Sharepoint is used as a secure place to store, organize, share, and access information from any device. All you need is a web browser and you are good to go.',
    logo: SharePointLogo,
    kbLink: KB_LINKS.SHAREPOINT_INTEGRATION,
    planCategory: 'standard'
  },
  {
    key: HAPPYFOX_HELPDESK.KEY,
    id: HAPPYFOX_HELPDESK.ID,
    category: [APP_CATEGORY.TICKETING_SYSTEM, APP_CATEGORY.KNOWLEDGE_SOURCES],
    title: 'HappyFox Helpdesk',
    description:
      'HappyFox is an all-in-one help desk ticketing system that provides faster and better support for your customers.',
    logo: HappyFoxLogo,
    kbLink: KB_LINKS.HAPPYFOX_HELPDESK_INTEGRATION,
    planCategory: 'standard'
  },
  {
    key: NOTION.KEY,
    id: NOTION.ID,
    category: APP_CATEGORY.KNOWLEDGE_SOURCES,
    title: 'Notion',
    description:
      'Notion is a project management and note-taking software.Notion is a software designed to help members of a company or organization coordinate deadlines, objectives, and assignments for the sake of efficiency and productivity.',
    logo: NotionLogo,
    kbLink: KB_LINKS.NOTION_INTEGRATION,
    planCategory: 'standard'
  },
  {
    key: SIMPPLR.KEY,
    id: SIMPPLR.ID,
    category: APP_CATEGORY.KNOWLEDGE_SOURCES,
    title: 'Simpplr',
    description:
      'Simpplr helps companies connect their workforce by streamlining internal communications and forging employee connections',
    logo: SimpplrLogo,
    kbLink: KB_LINKS.SIMPPLR_INTEGRATION,
    planCategory: 'premium'
  },
  {
    key: CONFLUENCE.KEY,
    id: CONFLUENCE.ID,
    category: APP_CATEGORY.KNOWLEDGE_SOURCES,
    title: 'Confluence',
    description:
      'Confluence is a team collaboration tool that gives your team a place to create, capture, and collaborate on any project or idea',
    logo: ConfluenceLogo,
    kbLink: KB_LINKS.CONFLUENCE_INTEGRATION,
    planCategory: 'standard'
  },
  {
    key: GURU.KEY,
    id: GURU.ID,
    category: APP_CATEGORY.KNOWLEDGE_SOURCES,
    title: 'Guru',
    description:
      'Guru is a collaborative knowledge management solution that is used to share knowledge within a company. With Guru, teams can effortlessly access knowledge from across your company.',
    logo: GuruLogo,
    kbLink: KB_LINKS.GURU_INTEGRATION,
    planCategory: 'standard'
  },
  {
    key: OTHER_TICKETING_SYSTEM.KEY,
    id: OTHER_TICKETING_SYSTEM.ID,
    route: 'category/ticketing-system/other',
    category: APP_CATEGORY.TICKETING_SYSTEM,
    title: 'Other Ticketing system',
    description: 'Connect to other ticketing system',
    logo: OtsImage,
    kbLink: KB_LINKS.OTHER_TICKETING_INTEGRATION,
    planCategory: 'standard'
  },
  {
    key: OKTA.KEY,
    id: OKTA.ID,
    category: APP_CATEGORY.IDENTITY_MANAGEMENT,
    title: 'Okta',
    description: `The Okta System for Cross-domain Identity Management (SCIM) allows admins to manage Users and User group information between Okta's Universal Directory and Assist AI. Integrating with Okta streamlines and automates managing user accounts, attributes between business systems.`,
    logo: OktaLogo,
    kbLink: KB_LINKS.OKTA_INTEGRATION,
    planCategory: 'premium'
  },
  {
    key: AZURE_AD.KEY,
    id: AZURE_AD.ID,
    category: APP_CATEGORY.IDENTITY_MANAGEMENT,
    title: 'Azure Active Directory',
    description: `Azure AD is Microsoft's cloud-based identity and access management service, providing a robust solution for managing users, groups, and applications. Seamlessly connect your Azure Active Directory with Assist AI to streamline user management and enhance productivity. With this integration, you can leverage quick actions and AI actions to automate common tasks and provide intelligent assistance to your users.`,
    logo: AzureADLogo,
    // kbLink: KB_LINKS.AZURE_AD, //TODO: enable it after KB is ready
    planCategory: 'premium'
  },
  {
    key: HAPPYFOX_SERVICE_DESK.KEY,
    id: HAPPYFOX_SERVICE_DESK.ID,
    category: APP_CATEGORY.TICKETING_SYSTEM,
    title: 'HappyFox Service Desk',
    description: `HappyFox Service Desk is a robust system that enables you to deliver employee support and achieve ITSM goals. By connecting to HappyFox Assist AI with Service Desk, your employees can raise queries, issues and requests from within Slack. Agents can resolve those incidents and service requests through conversational ticketing, quick actions and more!`,
    logo: HfServiceDeskLogo,
    // kbLink: KB_LINKS.HF_SERVICEDESK_INTEGRATION, TODO: Add KB link
    planCategory: 'standard'
  },
  {
    key: OUTLINE.KEY,
    id: OUTLINE.ID,
    category: APP_CATEGORY.KNOWLEDGE_SOURCES,
    title: 'Outline',
    description: `Outline is a powerful tool for creating structured outlines, organizing notes and project plans. Whether you're planning a project, drafting an article, or brainstorming ideas, Outline helps you structure your thoughts and keep everything in order. Now, with Assist AI integration, you can receive intelligent insights from Outline directly within Assist AI`,
    logo: OutlineLogo,
    planCategory: 'standard'
  }
]

export default apps
