// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FxgoS7X_1S9yDBtkM98u{margin-top:2rem}.pA2RNNNdFExRgS8bwb6y{display:flex;align-items:center;gap:20px;margin-right:auto}.gseT_0cIiaUa7MFOMOoF th:not(:first-child):not(:last-child){width:150px}.dc6SmjTmmsLeNJDs2ZcQ{text-align:center;margin:5rem 0}", "",{"version":3,"sources":["webpack://./../src/pages/protected/Answers/AnswersList/AnswersList.module.css"],"names":[],"mappings":"AAAA,sBACE,eAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CACA,QAAA,CACA,iBAAA,CAGF,4DACE,WAAA,CAGF,sBACE,iBAAA,CACA,aAAA","sourcesContent":[".Container {\n  margin-top: 2rem;\n}\n\n.SearchInputContainer {\n  display: flex;\n  align-items: center;\n  gap: 20px;\n  margin-right: auto;\n}\n\n.AnswerTable th:not(:first-child):not(:last-child) {\n  width: 150px;\n}\n\n.NoFilterResults {\n  text-align: center;\n  margin: 5rem 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Container": "FxgoS7X_1S9yDBtkM98u",
	"SearchInputContainer": "pA2RNNNdFExRgS8bwb6y",
	"AnswerTable": "gseT_0cIiaUa7MFOMOoF",
	"NoFilterResults": "dc6SmjTmmsLeNJDs2ZcQ"
};
export default ___CSS_LOADER_EXPORT___;
