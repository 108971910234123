import { ACCOUNT_TYPE } from './account'

/*
  `KEY` represent the value sent from BE
  `ID` represent the value used in FE for usecases like creating dynamic routes, apis, etc...
*/
const maps = {
  // apps
  SHAREPOINT: {
    KEY: 'sharepoint',
    ID: 'sharepoint'
  },
  HAPPYFOX_HELPDESK: {
    KEY: 'happyfox',
    ID: 'happyfox'
  },
  HAPPYFOX_SERVICE_DESK: {
    KEY: 'happyfox_service_desk',
    ID: 'happyfox-service-desk'
  },
  NOTION: {
    KEY: 'notion',
    ID: 'notion'
  },
  SIMPPLR: {
    KEY: 'simpplr',
    ID: 'simpplr'
  },
  CONFLUENCE: {
    KEY: 'confluence',
    ID: 'confluence'
  },
  GURU: {
    KEY: 'guru',
    ID: 'guru'
  },
  OTHER_TICKETING_SYSTEM: {
    KEY: 'other-ticketing-system', // TODO: change it to snake_case
    ID: 'other-ticketing-system'
  },
  OKTA: {
    KEY: 'okta',
    ID: 'okta'
  },
  AZURE_AD: {
    KEY: 'azure_ad',
    ID: 'azure-ad'
  },
  // account types
  MS_TEAMS: {
    KEY: ACCOUNT_TYPE.MS_TEAMS,
    ID: 'ms-teams'
  },
  SLACK: {
    KEY: ACCOUNT_TYPE.SLACK,
    ID: ACCOUNT_TYPE.SLACK
  },
  OUTLINE: {
    KEY: 'outline',
    ID: 'outline'
  }
}

const getKeyFromId = (id) => {
  const mapObj = Object.values(maps).find(({ ID }) => id === ID)
  if (!mapObj) return

  return mapObj.KEY
}

const getIdFromKey = (key) => {
  const mapObj = Object.values(maps).find(({ KEY }) => key === KEY)
  if (!mapObj) return

  return mapObj.ID
}

export default maps
export { getIdFromKey, getKeyFromId }
