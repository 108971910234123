import { Heading, Text } from '@happyfoxinc/react-ui'
import Markdown from 'marked-react'
import { useMemo } from 'react'
import { Navigate, useParams } from 'react-router-dom'

import styles from './AppDetails.module.css'

import { SecondaryButton } from 'Components/Buttons'
import NoRender from 'Components/NoRender'
import PageLoader from 'Components/PageLoader'
import PageTitle from 'Components/PageTitle'
import keyMap from 'Constants/key-map'
import api from 'Services/api'
import { openUrl } from 'Utils/url'

import appsList from '../apps-list'
import AppDetailContext, { useAppDetailContext } from './AppDetailContext'
import AzureAD from './apps/AzureAD'
import Confluence from './apps/Confluence'
import Guru from './apps/Guru'
import HappyFoxHelpdesk from './apps/HappyFoxHelpdesk'
import HappyFoxServiceDesk from './apps/HappyFoxServiceDesk'
import Notion from './apps/Notion'
import Okta from './apps/Okta'
import Outline from './apps/Outline'
import SharePoint from './apps/SharePoint'
import Simpplr from './apps/Simpplr'

const {
  SHAREPOINT,
  HAPPYFOX_HELPDESK,
  HAPPYFOX_SERVICE_DESK,
  NOTION,
  SIMPPLR,
  CONFLUENCE,
  GURU,
  OKTA,
  AZURE_AD,
  OUTLINE
} = keyMap

const DefaultComponent = () => 'Invalid App'

const componentList = {
  [HAPPYFOX_HELPDESK.ID]: HappyFoxHelpdesk,
  [NOTION.ID]: Notion,
  [GURU.ID]: Guru,
  [SIMPPLR.ID]: Simpplr,
  [CONFLUENCE.ID]: Confluence,
  [SHAREPOINT.ID]: SharePoint,
  [OKTA.ID]: Okta,
  [AZURE_AD.ID]: AzureAD,
  [HAPPYFOX_SERVICE_DESK.ID]: HappyFoxServiceDesk,
  [OUTLINE.ID]: Outline
}

const AppDetail = () => {
  const detail = useAppDetailContext()
  const Integration = componentList[detail.id] || DefaultComponent
  const Logo = detail.logo || NoRender

  return (
    <div className={styles.Container}>
      <div className={styles.SideContent}>
        <div className={styles.LogoContainer}>
          <Logo className={styles.BrandImage} />
        </div>
        <Heading level={2} className={styles.Title}>
          {detail.title}
        </Heading>
        <Text as='div' size='sm' variant='muted' className={styles.Description}>
          <Markdown>{detail.description}</Markdown>
        </Text>
        {detail.kbLink && (
          <SecondaryButton size='sm' onClick={() => openUrl(detail.kbLink)}>
            Detailed Guide
          </SecondaryButton>
        )}
      </div>
      <div className={styles.MainContent}>
        <Integration />
      </div>
    </div>
  )
}

const AppDetails = () => {
  const { appId } = useParams()
  const { data: apps = [], isLoading } = api.useGetAppsQuery()

  const detail = useMemo(() => {
    const appDetail = appsList.find(({ id }) => id === appId)
    if (!appDetail) return

    const app = apps.find(({ key }) => key === appDetail.key)
    if (!app) return

    return appDetail
  }, [apps, appId])

  if (isLoading) {
    return <PageLoader />
  }

  if (!detail) {
    return <Navigate to='../' replace />
  }

  return (
    <AppDetailContext.Provider value={{ ...detail }}>
      <PageTitle backTo='../'>Apps</PageTitle>
      <AppDetail />
    </AppDetailContext.Provider>
  )
}

export default AppDetails
