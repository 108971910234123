import { Heading, Text } from '@happyfoxinc/react-ui'
import { Fragment, useState } from 'react'
import toast from 'react-hot-toast'

// import Sheet from 'Layout/Sheet'

import ConfirmationModal from 'Components/ConfirmationModal'
import { CONFIRM_MESSAGES, TOAST_MESSAGES } from 'Constants/messages'
import api from 'Services/api'
import { useWorkspace } from 'Utils/hooks/useWorkspace'

import AppFooter from '../../../components/AppFooter'
import { InstallAppBase } from '../../../components/InstallApp'
// import KnowledgeConfiguration from '../../../KnowledgeConfiguration'
import SyncDisabledAlert from '../../../components/SyncDisabledAlert'
import { useAppDetailContext } from '../../AppDetailContext'
import NotionAccounts from './NotionAccounts'
// import NotionSelectPages from './NotionSelectPages'
import NotionSelectPages from './NotionOldSelectPages'
import NotionPages from './NotionPages'

// const BrowsePages = () => {
//   const [showModal, setShowModal] = useState(false)

//   const handleClick = () => {
//     setShowModal(true)
//   }

//   const handleClose = () => {
//     setShowModal(false)
//   }

//   return (
//     <Fragment>
//       {showModal && (
//         <Sheet onHide={handleClose} title='Select Notion page(s) to sync from'>
//           <NotionSelectPages onHide={handleClose} />
//         </Sheet>
//       )}
//       <Button variant='primary' size='sm' onClick={handleClick}>
//         Browse Pages
//       </Button>
//     </Fragment>
//   )
// }

const ManageNotion = (props) => {
  const { app } = props
  const { currentWorkspaceId } = useWorkspace()

  const { title } = useAppDetailContext()

  const [enableSync, enableSyncResult] = api.useEnableSyncNotionMutation()
  const [isEnableSyncModalOpen, setIsEnableSyncModalOpen] = useState(false)

  const handleEnableSync = () => {
    if (enableSyncResult.isLoading) return

    const promise = enableSync({ workspace_id: currentWorkspaceId }).unwrap()
    promise.then(() => setIsEnableSyncModalOpen(false)).catch(() => {})
    toast.promise(promise, TOAST_MESSAGES.ENABLE_SYNC(title))
  }

  const isSyncDisabled = !app.sync_info.is_sync_enabled

  return (
    <Fragment>
      {isSyncDisabled && <SyncDisabledAlert onEnableSync={() => setIsEnableSyncModalOpen(true)} />}
      <InstallAppBase>
        <Heading level={2}>
          Successfully connected to {app.connected_workspace} workspace. <br /> The account is tied to{' '}
          <Text isInline variant='muted'>
            {app.user_email_id}
          </Text>
        </Heading>
      </InstallAppBase>
      {app.is_linked_by_other_users && <NotionAccounts />}
      {(app.is_linked || app.is_linked_by_other_users) && <NotionPages />}
      {/* <KnowledgeConfiguration app={app} additionalTableOptions={<BrowsePages />} /> */}
      <NotionSelectPages />
      <AppFooter app={app} />
      <ConfirmationModal
        isOpen={isEnableSyncModalOpen}
        message={CONFIRM_MESSAGES.ENABLE_APP_SYNC(title)}
        onCancel={() => setIsEnableSyncModalOpen(false)}
        onConfirm={handleEnableSync}
        title='Enable Sync'
      />
    </Fragment>
  )
}

export default ManageNotion
