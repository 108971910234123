import { ACCOUNT_TYPE } from 'Constants/account'
import { MS_TEAMS_ID_KEY, SLACK_ID_KEY, USER_MANAGEMENT_TYPE } from 'Constants/user-groups'

const { MANUAL, SYNCED_FROM_APP } = USER_MANAGEMENT_TYPE

export const createPayloadFromUserGroupForm = ({ name, users, addedFrom, syncedFromGroup }, accountType) => {
  if (addedFrom.value === MANUAL) {
    const idKey = getUserIdKey(accountType)
    return {
      name,
      users: users.map((user) => user[idKey]),
      user_management_type: MANUAL
    }
  }
  return {
    name,
    sync_from_app: addedFrom.value,
    user_management_type: SYNCED_FROM_APP,
    sync_from_group: syncedFromGroup.id
  }
}

export const getUserIdKey = (accountType) => {
  if (accountType === ACCOUNT_TYPE.SLACK) {
    return SLACK_ID_KEY
  }
  if (accountType === ACCOUNT_TYPE.MS_TEAMS) {
    return MS_TEAMS_ID_KEY
  }
}
