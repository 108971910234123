// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".zPZirxD7e7Yn9LF7dH1J{display:flex;flex-direction:column;gap:1rem;align-items:flex-start;padding:3rem;background-color:var(--main-bg);width:100%;border-radius:var(--border-radius)}.ooWVbnT0iBHVA1n7dEDb{display:flex;gap:1rem;margin-top:1rem}", "",{"version":3,"sources":["webpack://./../src/pages/protected/Apps/components/InstallApp/InstallApp.module.css"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,QAAA,CACA,sBAAA,CACA,YAAA,CACA,+BAAA,CACA,UAAA,CACA,kCAAA,CAGF,sBACE,YAAA,CACA,QAAA,CACA,eAAA","sourcesContent":[".Container {\n  display: flex;\n  flex-direction: column;\n  gap: 1rem;\n  align-items: flex-start;\n  padding: 3rem;\n  background-color: var(--main-bg);\n  width: 100%;\n  border-radius: var(--border-radius);\n}\n\n.PlanButtonGroup {\n  display: flex;\n  gap: 1rem;\n  margin-top: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Container": "zPZirxD7e7Yn9LF7dH1J",
	"PlanButtonGroup": "ooWVbnT0iBHVA1n7dEDb"
};
export default ___CSS_LOADER_EXPORT___;
