import { usePlanContext } from 'Components/Plan'
import ReactSelect from 'Components/ReactSelect'

import INTERVALS from './interval-configs'

const IntervalSelection = ({ value, onChange }) => {
  const { planName } = usePlanContext()

  const intervalOptions = INTERVALS.filter(({ availableFor }) => availableFor.includes(planName))

  return <ReactSelect value={value} onChange={onChange} placeholder='Select Interval' options={intervalOptions} />
}

export default IntervalSelection
