import { Button, Checkbox, Table, TableBody, TableHead, TableRow, Td, Text, Th } from '@happyfoxinc/react-ui'
import { Fragment, useMemo, useState } from 'react'
import toast from 'react-hot-toast'
import { useTable } from 'react-table'

import styles from './AddCollections.module.css'

import api from 'Services/api'
import PageLoader from 'Src/components/PageLoader'

const AddCollectionsTable = ({ data, checkedItems, setCheckedItems }) => {
  const columns = useMemo(
    () => [
      {
        Header: 'Collections',
        accessor: 'name'
      }
    ],
    []
  )

  const { getTableProps, headerGroups, getTableBodyProps, rows, prepareRow } = useTable({
    columns,
    data
  })

  const handleRowClick = (id) => {
    setCheckedItems((prev) => (prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]))
  }

  const isChecked = (id) => checkedItems.includes(id)

  return (
    <Table className={styles.AddCollectionsTable} {...getTableProps()}>
      <TableHead>
        {headerGroups.map((headerGroup) => {
          const { key, ...headerGroupProps } = headerGroup.getHeaderGroupProps()

          return (
            <TableRow key={key} {...headerGroupProps} style={{ backgroundColor: '#EEEEEE' }}>
              {headerGroup.headers.map((column) => {
                const { key, ...headerProps } = column.getHeaderProps()

                return (
                  <Th key={key} {...headerProps} className={styles.TableCell}>
                    <Text variant='muted'>{column.render('Header')}</Text>
                  </Th>
                )
              })}
            </TableRow>
          )
        })}
      </TableHead>

      <TableBody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row)
          const { key, ...rowProps } = row.getRowProps()
          const rowId = row.original.id

          return (
            <TableRow key={key} {...rowProps} clickable onClick={() => handleRowClick(rowId)}>
              {row.cells.map((cell) => {
                const { key, ...cellProps } = cell.getCellProps()
                return (
                  <Td className={styles.TableCell} key={key} {...cellProps}>
                    <Checkbox
                      checked={isChecked(rowId)}
                      onCheckedChange={() => handleRowClick(rowId)}
                      label={cell.render('Cell')}
                    />
                  </Td>
                )
              })}
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  )
}

const AddCollections = () => {
  const [checkedItems, setCheckedItems] = useState([])
  const { data, isLoading: isCollectionsLoading } = api.useGetCollectionsQuery()
  const [addCollections, addCollectionsResult] = api.useAddCollectionsMutation()

  const handleAddCollections = () => {
    const promise = addCollections({ collection_ids: checkedItems }).unwrap()
    promise.then(() => setCheckedItems([]))

    toast.promise(promise, {
      loading: 'Adding collections',
      success: 'Collections added successfully',
      error: 'Unable to add collections. Try again'
    })
  }

  if (isCollectionsLoading) {
    return <PageLoader />
  }

  const disableAddButton = checkedItems.length === 0 || addCollectionsResult.isLoading

  return (
    <Fragment>
      <Text>Add a collections to sync from</Text>
      <AddCollectionsTable data={data} checkedItems={checkedItems} setCheckedItems={setCheckedItems} />
      <Button
        onClick={handleAddCollections}
        className={styles.AddCollectionsButton}
        variant='primary'
        disabled={disableAddButton}
      >
        Add Collections
      </Button>
    </Fragment>
  )
}

export default AddCollections
