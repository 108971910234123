import { Heading, Text } from '@happyfoxinc/react-ui'
import { Fragment, useState } from 'react'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'

import { SecondaryButton } from 'Components/Buttons'
import ConfirmationModal from 'Components/ConfirmationModal'
import { CONFIRM_MESSAGES, TOAST_MESSAGES } from 'Constants/messages'
import api from 'Services/api'
import parseErrorMessage from 'Utils/error-message-parser'

import AppFooter from '../../../components/AppFooter'
import { InstallAppBase } from '../../../components/InstallApp'
import SyncDisabledAlert from '../../../components/SyncDisabledAlert'
import KnowledgeConfiguration from '../../../KnowledgeConfiguration'
import { useAppDetailContext } from '../../AppDetailContext'
import SimpplrSites from './SimpplrSites'

const ManageSimpplr = (props) => {
  const { app } = props
  const { title } = useAppDetailContext()
  const navigate = useNavigate()

  const [enableSync, enableSyncResult] = api.useEnableSyncSimpplrMutation()
  const [isEnableSyncModalOpen, setIsEnableSyncModalOpen] = useState(false)

  const [uninstall, uninstallResult] = api.useUninstallSimpplrMutation()
  const [isUninstallModalOpen, setIsUninstallModalOpen] = useState(false)
  const handleUninstall = () => {
    const promise = uninstall().unwrap()
    promise.then(() => setIsUninstallModalOpen(false))

    toast.promise(promise, {
      loading: `Uninstalling ${title}`,
      success: `${title} integration uninstalled successfully`,
      error: parseErrorMessage(`Unable to uninstall ${title}. Try again`)
    })
  }

  const handleChangeAuthClick = () => {
    navigate('auth')
  }

  const handleEnableSync = () => {
    if (enableSyncResult.isLoading) return

    const promise = enableSync().unwrap()
    promise.then(() => setIsEnableSyncModalOpen(false)).catch(() => {})
    toast.promise(promise, TOAST_MESSAGES.ENABLE_SYNC(title))
  }

  const isSyncDisabled = !app.sync_info.is_sync_enabled

  return (
    <Fragment>
      {isSyncDisabled && <SyncDisabledAlert onEnableSync={() => setIsEnableSyncModalOpen(true)} />}
      <InstallAppBase>
        <Heading level={2}>
          Successfully connected to {title} workspace. <br /> The account is tied to{' '}
          <Text isInline variant='muted'>
            {app.account_url}
          </Text>
        </Heading>
        <SecondaryButton onClick={handleChangeAuthClick}>Reconnect</SecondaryButton>
      </InstallAppBase>

      <SimpplrSites />
      <KnowledgeConfiguration app={app} />

      <AppFooter
        onUninstall={() => setIsUninstallModalOpen(true)}
        isUninstalling={uninstallResult.isLoading}
        app={app}
      />
      <ConfirmationModal
        isOpen={isUninstallModalOpen}
        variant='alert'
        message={CONFIRM_MESSAGES.UNINSTALL_APP}
        onCancel={() => setIsUninstallModalOpen(false)}
        onConfirm={handleUninstall}
      />
      <ConfirmationModal
        isOpen={isEnableSyncModalOpen}
        message={CONFIRM_MESSAGES.ENABLE_APP_SYNC(title)}
        onCancel={() => setIsEnableSyncModalOpen(false)}
        onConfirm={handleEnableSync}
        title='Enable Sync'
      />
    </Fragment>
  )
}

export default ManageSimpplr
