import { Button } from '@happyfoxinc/react-ui'
import { yupResolver } from '@hookform/resolvers/yup'
import { Fragment, useCallback, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { Navigate, useNavigate, useParams } from 'react-router-dom'

import styles from './UserGroup.module.scss'

import ConfirmationModal from 'Components/ConfirmationModal'
import PageLoader from 'Components/PageLoader'
import PageTitle from 'Components/PageTitle'
import { APP_SYNCED_FROM_DEFINITIONS, USER_MANAGEMENT_TYPE, USERGROUP_DELETE_MESSAGE } from 'Constants/user-groups'
import api from 'Services/api'
import parseErrorMessage from 'Utils/error-message-parser'

import { createPayloadFromUserGroupForm } from '../usergroups-helper'
import SyncedUsersStatus from './SyncedUsersStatus'
import UserGroupForm from './UserGroupForm'
import userGroupFormValidationSchema from './UserGroupForm/user-group-form-validation-schema'

const { MANUAL, SYNCED_FROM_APP } = USER_MANAGEMENT_TYPE
const createFormValues = (ug) => {
  const addedFrom =
    ug.user_management_type === MANUAL
      ? APP_SYNCED_FROM_DEFINITIONS[MANUAL]
      : APP_SYNCED_FROM_DEFINITIONS[ug.sync_from_app]
  return {
    name: ug.name,
    users: ug.users,
    addedFrom,
    syncedFromGroup: ug.sync_from_group
  }
}

const UserGroupFormHandler = (props) => {
  const { userGroup, isRefreshing, onRefresh } = props
  const navigate = useNavigate()
  const { data: account } = api.useGetAccountQuery()
  const accountType = account.account_type

  const formMethods = useForm({
    defaultValues: createFormValues(userGroup),
    resolver: yupResolver(userGroupFormValidationSchema)
  })
  const {
    handleSubmit,
    formState: {
      isSubmitting,
      dirtyFields: { addedFrom: isAddedFromEdited, syncedFromGroup: isSyncedFromGroupEdited }
    }
  } = formMethods

  const [updateUserGroup, updateUserGroupResult] = api.useUpdateUserGroupMutation()
  const [deleteUserGroup, deleteUserGroupResult] = api.useDeleteUserGroupMutation()

  const handleFormSubmit = async (formData) => {
    const payload = {
      ...createPayloadFromUserGroupForm(formData, accountType),
      id: userGroup.id,
      accountType
    }
    const promise = updateUserGroup(payload).unwrap()

    toast.promise(promise, {
      loading: 'Updating user group',
      success: 'User group updated successfully',
      error: parseErrorMessage('Unable to update user group. Try again...')
    })
    promise.then((value) => formMethods.reset(createFormValues(value))).catch(() => {})
  }

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

  const handleDeleteUserGroup = useCallback(() => {
    const payload = {
      id: userGroup.id,
      accountType
    }
    const promise = deleteUserGroup(payload).unwrap()
    promise.then(() => setIsDeleteModalOpen(false))

    toast.promise(promise, {
      loading: 'Deleting user group...',
      success: 'User group deleted successfully.',
      error: parseErrorMessage('Unable to delete user group. Try again...')
    })

    promise.then(() => {
      navigate('../')
    })
  }, [userGroup.id, deleteUserGroup, navigate, accountType])

  const handleCancel = () => {
    navigate('..')
  }

  const isLoading = updateUserGroupResult.isLoading || deleteUserGroupResult.isLoading

  const {
    mapped_knowledge_scopes: { length: knowledgeScopeLength }
  } = userGroup
  const { KB_ASSOCIATED, NO_KB_ASSOCIATED } = USERGROUP_DELETE_MESSAGE
  const deleteConfirmationMessage = knowledgeScopeLength > 0 ? KB_ASSOCIATED : NO_KB_ASSOCIATED

  const [reSyncUserGroup] = api.useReSyncUserGroupMutation()
  const handleReSync = () => {
    const { id } = userGroup
    const payload = { id, accountType }
    const promise = reSyncUserGroup(payload).unwrap()

    toast.promise(promise, {
      loading: `Starting the User Group Re-sync`,
      success: `Started the User Group Re-sync`,
      error: parseErrorMessage(`Unable to Re-sync the User Group. Try again...`)
    })
  }
  const isSyncedFromApp = userGroup.user_management_type === SYNCED_FROM_APP
  const disableUpdateButton = isSubmitting || isLoading

  return (
    <Fragment>
      <div className={styles.FormContainer}>
        <div className={styles.PageTitle}>
          <PageTitle className={styles.PageTitle} backTo='../'>
            Edit User Group
          </PageTitle>
        </div>
        <FormProvider {...formMethods}>
          <UserGroupForm userGroup={userGroup} />
        </FormProvider>
        {isSyncedFromApp && !isAddedFromEdited && !isSyncedFromGroupEdited && (
          <SyncedUsersStatus
            userGroup={userGroup}
            onReSync={handleReSync}
            isRefreshing={isRefreshing}
            onRefresh={onRefresh}
          />
        )}
        <div className={styles.ButtonContainer}>
          <Button variant='primary' onClick={handleSubmit(handleFormSubmit)} disabled={disableUpdateButton}>
            Update
          </Button>
          <Button variant='link-muted' onClick={handleCancel}>
            Cancel
          </Button>
          <Button variant='link-danger' className={styles.DeleteButton} onClick={() => setIsDeleteModalOpen(true)}>
            Delete
          </Button>
        </div>
      </div>

      <ConfirmationModal
        isOpen={isDeleteModalOpen}
        variant='alert'
        message={deleteConfirmationMessage}
        onCancel={() => setIsDeleteModalOpen(false)}
        onConfirm={handleDeleteUserGroup}
      />
    </Fragment>
  )
}

const EditUserGroup = () => {
  const { id } = useParams()
  const { data: account } = api.useGetAccountQuery()
  const accountType = account.account_type

  const query = {
    id,
    accountType
  }
  const { isLoading, isFetching, data: userGroup, isError, error, refetch } = api.useGetUserGroupQuery(query)

  const handleRefresh = () => {
    refetch()
  }

  if (isLoading) {
    return <PageLoader />
  }

  if (isError && error.originalStatus === 404) {
    return <Navigate to='..' replace />
  }

  return <UserGroupFormHandler userGroup={userGroup} isRefreshing={isFetching} onRefresh={handleRefresh} />
}

export default EditUserGroup
