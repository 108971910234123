import PLANS from 'Constants/plans'

export default [
  {
    label: 'All Time',
    value: 'all_time',
    availableFor: [PLANS.ENTERPRISE]
  },
  {
    label: 'Last 365 Days',
    value: 'last_365_days',
    availableFor: [PLANS.ENTERPRISE, PLANS.PRO]
  },
  {
    label: 'Last 30 days',
    value: 'last_30_days',
    availableFor: [PLANS.ENTERPRISE, PLANS.PRO, PLANS.FREE],
    isDefault: true
  },
  {
    label: 'Last 7 days',
    value: 'last_7_days',
    availableFor: [PLANS.ENTERPRISE, PLANS.PRO, PLANS.FREE]
  }
]
