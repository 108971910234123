import { Button, FormControl, FormErrorText, Input, Label } from '@happyfoxinc/react-ui'
import { Fragment, useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import styles from './AnswerForm.module.css'

import { SecondaryButton } from 'Components/Buttons'
import { Feature } from 'Components/Features'
import MergeTrixEditor from 'Components/MergeTrixEditor'
import PageTitle from 'Components/PageTitle'
import ReactSelect from 'Components/ReactSelect'
import Tips, { Tip } from 'Components/Tips'
import { ANSWER_CONTENT_MERGE_FIELDS } from 'Constants/mergeFields'
import { ANSWER_VISIBILITY_OPTIONS, KNOWLEDGE_GROUP_VISIBILITY_STATES } from 'Constants/user-groups'
import api from 'Services/api'
import { useWorkspace } from 'Utils/hooks/useWorkspace'

export const getPayloadFromFormData = (data) => {
  const payload = {
    name: data.name,
    content: data.content,
    workspace_id: data.workspace_id
  }

  if (data.visibility) {
    payload.visibility = data.visibility
  }

  if (data.visibleToGroups) {
    payload.mapped_user_groups = data.visibleToGroups.map((group) => group.id)
  }

  return payload
}

const AnswerForm = (props) => {
  const { isEdit = false, isLoading, onSubmit, onSaveAndAdd, onDeleteAnswer } = props
  const { currentWorkspaceId, workspaces } = useWorkspace()

  const navigate = useNavigate()
  const { data: account } = api.useGetAccountQuery()
  const accountType = account.account_type
  const showWorkspaceDropdown = account?.is_workspaces_enabled

  const [getUserGroups, getUserGroupsResult] = api.useLazyGetUserGroupsQuery()

  const loadOptions = (inputValue, cb) => {
    const query = {
      search: inputValue,
      accountType
    }
    getUserGroups(query)
      .unwrap()
      .then((data) => {
        cb(data.results)
      })
  }

  const formMethods = useFormContext()
  const {
    register,
    control,
    formState: { isSubmitting, errors },
    watch,
    setValue
  } = formMethods

  useEffect(() => {
    if (!watch('workspace_id')) {
      setValue('workspace_id', currentWorkspaceId)
    }
  }, [currentWorkspaceId, setValue, watch])

  const disableSubmitButton = isSubmitting || isLoading
  const visibility = watch('visibility')

  const handleBackClick = () => {
    navigate('../')
  }

  return (
    <Fragment>
      <PageTitle backTo='../'>Answers</PageTitle>
      <div className={styles.Container}>
        <div className={styles.MainContent}>
          <div className={styles.AnswerContent}>
            <form>
              <FormControl isInvalid={errors.name}>
                <Label>Answer Title</Label>
                <Input className={styles.TitleInput} {...register('name')} />
                {errors.name && <FormErrorText>{errors.name.message}</FormErrorText>}
              </FormControl>

              <FormControl isInvalid={errors.content}>
                <MergeTrixEditor name='content' mergeFields={ANSWER_CONTENT_MERGE_FIELDS} />
                {errors.content && <FormErrorText>{errors.content.message}</FormErrorText>}
              </FormControl>
              {!isEdit && showWorkspaceDropdown && (
                <FormControl>
                  <Label>Workspace</Label>
                  <Controller
                    name='workspace_id'
                    control={control}
                    render={({ field }) => {
                      return (
                        <ReactSelect
                          {...field}
                          isDisabled
                          value={workspaces.find((opt) => opt.id === field.value)}
                          onChange={(opt) => field.onChange(opt.id)}
                          isClearable={false}
                          options={workspaces}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                        />
                      )
                    }}
                  />
                  {errors.workspace && <FormErrorText>{errors.workspace.message}</FormErrorText>}
                </FormControl>
              )}
              <FormControl>
                <div className={styles.AnswerVisibilityContainer}>
                  <Feature features={['user_groups']}>
                    <FormControl isInvalid={errors.visibility} className={styles.AnswerVisibility}>
                      <Label>Show answer to</Label>
                      <Controller
                        name='visibility'
                        control={control}
                        render={({ field }) => {
                          return (
                            <ReactSelect
                              {...field}
                              value={ANSWER_VISIBILITY_OPTIONS.find((opt) => opt.value === field.value)}
                              onChange={(opt) => field.onChange(opt.value)}
                              isClearable={false}
                              options={ANSWER_VISIBILITY_OPTIONS}
                              getOptionLabel={(option) => option.label}
                              getOptionValue={(option) => option.value}
                            />
                          )
                        }}
                      />
                      {errors.visibility && <FormErrorText>{errors.visibility.message}</FormErrorText>}
                    </FormControl>
                  </Feature>

                  <div className={styles.VisibilityGroup}>
                    {visibility === KNOWLEDGE_GROUP_VISIBILITY_STATES.SPECIFIC && (
                      <FormControl isInvalid={errors.visibleToGroups}>
                        <Label>Select user group(s) for which the answer will be recommended</Label>
                        <Controller
                          name='visibleToGroups'
                          control={control}
                          shouldUnregister
                          render={({ field }) => {
                            return (
                              <ReactSelect
                                {...field}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.id}
                                placeholder='Search user groups'
                                isClearable={false}
                                isMulti
                                isInvalid={Boolean(errors.users)}
                                options={[]}
                                loadOptions={loadOptions}
                                isLoading={getUserGroupsResult.isLoading}
                                loadingMessage={() => 'Searching for user groups...'}
                              />
                            )
                          }}
                        />
                        {errors.visibleToGroups && <FormErrorText>{errors.visibleToGroups.message}</FormErrorText>}
                      </FormControl>
                    )}
                  </div>
                </div>
              </FormControl>

              <div className={styles.ButtonContainer}>
                <Button variant='primary' disabled={disableSubmitButton} onClick={onSubmit}>
                  Save
                </Button>

                <SecondaryButton disabled={disableSubmitButton} className={styles.SaveAdd} onClick={onSaveAndAdd}>
                  Save and Add another
                </SecondaryButton>

                {!onDeleteAnswer && (
                  <Button className={styles.DeleteButton} variant='link-muted' onClick={handleBackClick}>
                    Cancel
                  </Button>
                )}
                {onDeleteAnswer && (
                  <Button className={styles.DeleteButton} variant='link-danger' onClick={onDeleteAnswer}>
                    Delete
                  </Button>
                )}
              </div>
            </form>
          </div>
        </div>
        <div className={styles.SidebarContent}>
          <Tips title='Tips to add answers content'>
            <Tip>
              Ensure your answers are clear, concise, and consist of the required keywords — Assist AI will
              automatically parse Answers for keywords and phrases
            </Tip>
            <Tip>
              Images from the web can be copied and pasted into an answer, but local images from the device are not
              supported.
            </Tip>
            <Tip>Once you have created an answer, hit Save to add more</Tip>
            <Tip>
              These Answers will be displayed to users as search results when they type their question to Assist AI.
            </Tip>
          </Tips>
        </div>
      </div>
    </Fragment>
  )
}

export default AnswerForm
