import { TableOption, TableOptions } from '@happyfoxinc/react-ui'
import React, { Fragment, useCallback, useState } from 'react'
import toast from 'react-hot-toast'

import ConfirmationModal from 'Components/ConfirmationModal'
import { USERGROUP_DELETE_MESSAGE } from 'Constants/user-groups'
import api from 'Services/api'
import parseErrorMessage from 'Utils/error-message-parser'

const UserGroupOptions = ({ id, mapped_knowledge_scopes: { length: knowledgeScopeLength } }) => {
  const { data: account } = api.useGetAccountQuery()
  const accountType = account.account_type

  const [deleteUserGroup] = api.useDeleteUserGroupMutation()
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

  const { KB_ASSOCIATED, NO_KB_ASSOCIATED } = USERGROUP_DELETE_MESSAGE
  const deleteConfirmationMessage = knowledgeScopeLength > 0 ? KB_ASSOCIATED : NO_KB_ASSOCIATED

  const handleDelete = useCallback(() => {
    const payload = {
      id,
      accountType
    }
    const promise = deleteUserGroup(payload).unwrap()
    promise.then(() => setIsDeleteModalOpen(false))

    toast.promise(promise, {
      loading: 'Deleting User group...',
      success: 'User group deleted successfully.',
      error: parseErrorMessage('Unable to delete user group. Try again...')
    })
  }, [deleteUserGroup, id, accountType])

  return (
    <Fragment>
      <TableOptions>
        <TableOption onClick={() => setIsDeleteModalOpen(true)}>Delete</TableOption>
      </TableOptions>
      <ConfirmationModal
        isOpen={isDeleteModalOpen}
        variant='alert'
        message={deleteConfirmationMessage}
        onCancel={() => setIsDeleteModalOpen(false)}
        onConfirm={handleDelete}
      />
    </Fragment>
  )
}

export default UserGroupOptions
