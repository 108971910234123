import { FormControl, FormErrorText, Input, Label } from '@happyfoxinc/react-ui'
import { Fragment } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import styles from './UserGroupForm.module.css'

import ReactSelect from 'Components/ReactSelect'
import { ACCOUNT_TYPE } from 'Constants/account'
import { APP_NOT_INSTALLED_MSG, USER_MANAGEMENT_TYPE } from 'Constants/user-groups'
import api from 'Services/api'

import { getUserIdKey } from './../../usergroups-helper'
import AddedFromField from './AddedFromField'

const UserGroupForm = ({ userGroup = {} }) => {
  const { sync_from_group: syncedFromGroup } = userGroup
  const { MANUAL } = USER_MANAGEMENT_TYPE
  const { data: account } = api.useGetAccountQuery()
  const accountType = account.account_type
  const isMsTeamsAccountType = account.account_type === ACCOUNT_TYPE.MS_TEAMS

  const [getUsers, getUsersResult] = api.useLazyGetUsersQuery()

  const {
    register,
    control,
    formState: { errors },
    watch,
    setValue
  } = useFormContext()
  const addedFromField = watch('addedFrom')
  const isUserManuallyAdded = addedFromField?.value === MANUAL

  const loadOptions = (inputValue, cb) => {
    const query = {
      name: inputValue,
      accountType
    }
    getUsers(query)
      .unwrap()
      .then((data) => {
        const options = data.map(({ is_app_installed, ...rest }) => {
          const disabled = isMsTeamsAccountType ? !is_app_installed : false
          return {
            disabled,
            ...rest
          }
        })
        cb(options)
      })
  }

  const [getAppUserGroups, getAppUserGroupsResult] = api.useLazyGetAppUserGroupsQuery()
  const loadAppUserGroupOptions = (inputValue, cb) => {
    getAppUserGroups({ search: inputValue, appName: addedFromField.value })
      .unwrap()
      .then((data) => {
        cb(data)
      })
  }
  const appUserGroupDefaultOptions = syncedFromGroup ? [syncedFromGroup] : []
  const handleAddedFromChange = () => {
    setValue('syncedFromGroup', null, { shouldDirty: true })
    setValue('users', [])
  }

  const getUsersFieldOptionValue = (opt) => {
    const idKey = getUserIdKey(accountType)
    return opt[idKey]
  }

  const getUsersFieldOptionLabel = (opt) => {
    if (opt.disabled) {
      return `${opt.name} (${APP_NOT_INSTALLED_MSG})`
    }
    return opt.name
  }

  return (
    <form className={styles.UserGroupFormGrid}>
      <FormControl className={styles.GroupNameField} isInvalid={errors.name}>
        <Label>Group Name</Label>
        <Input {...register('name')} />
        {errors.name && <FormErrorText>{errors.name.message}</FormErrorText>}
      </FormControl>

      <AddedFromField onChange={handleAddedFromChange} />

      {addedFromField && (
        <Fragment>
          {!isUserManuallyAdded && (
            <Fragment>
              <FormControl className={styles.SelectGroupField} isInvalid={errors.syncedFromGroup}>
                <Label>Select Group</Label>
                <Controller
                  name='syncedFromGroup'
                  control={control}
                  render={({ field }) => {
                    return (
                      <ReactSelect
                        {...field}
                        placeholder='Search groups'
                        defaultOptions={appUserGroupDefaultOptions}
                        minCharsBeforeLoadingOptions={3}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        loadOptions={loadAppUserGroupOptions}
                        isLoading={getAppUserGroupsResult.isLoading}
                        loadingMessage={() => 'Searching for groups...'}
                        noOptionsMessage={({ inputValue }) => {
                          if (inputValue.length < 3) {
                            return 'Type alteast 3 characters to start searching'
                          }

                          return `No groups found for input "${inputValue}"`
                        }}
                      />
                    )
                  }}
                />
                {errors.syncedFromGroup && <FormErrorText>{errors.syncedFromGroup.message}</FormErrorText>}
              </FormControl>
            </Fragment>
          )}
          {isUserManuallyAdded && (
            <FormControl className={styles.UsersField}>
              <Label>Users</Label>
              <Controller
                control={control}
                name='users'
                render={({ field }) => {
                  return (
                    <ReactSelect
                      {...field}
                      placeholder='Search users'
                      isClearable={false}
                      isMulti
                      isInvalid={Boolean(errors.users)}
                      options={[]}
                      getOptionLabel={getUsersFieldOptionLabel}
                      getOptionValue={getUsersFieldOptionValue}
                      loadOptions={loadOptions}
                      isOptionDisabled={(opt) => opt.disabled}
                      isLoading={getUsersResult.isLoading}
                      loadingMessage={() => 'Searching for users...'}
                      minCharsBeforeLoadingOptions={2}
                      noOptionsMessage={({ inputValue }) => {
                        if (inputValue.length < 2) {
                          return 'Type alteast 2 characters to start searching'
                        }
                        return `No users found for input "${inputValue}"`
                      }}
                      components={{
                        DropdownIndicator: null
                      }}
                      styles={{
                        control: (styles) => ({
                          ...styles,
                          minHeight: '5rem',
                          alignItems: 'flex-start'
                        })
                      }}
                    />
                  )
                }}
              />
              {errors.users && <FormErrorText>{errors.users.message}</FormErrorText>}
            </FormControl>
          )}
        </Fragment>
      )}
    </form>
  )
}

export default UserGroupForm
