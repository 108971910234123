import { Text } from '@happyfoxinc/react-ui'
import { Fragment, useState } from 'react'
import toast from 'react-hot-toast'
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom'

import styles from './HappyFoxHelpdesk.module.css'

import ConfirmationModal from 'Components/ConfirmationModal'
// import { FeatureProtected, ROUTE_FEATURE_GROUP, useFeature } from 'Components/Features'
import PageLoader from 'Components/PageLoader'
// import FEATURE_FLAGS, { CLIENT_FEATURE_FLAGS } from 'Constants/feature-flags'
import { CONFIRM_MESSAGES, TOAST_MESSAGES } from 'Constants/messages'
import { SYNC_STATUS } from 'Constants/syncStatus'
import PageTabs, { PageTab, PageTabList } from 'Layout/PageTabs'
import api from 'Services/api'

import SyncDisabledAlert from '../../../components/SyncDisabledAlert'
import KnowledgeConfiguration from '../../../KnowledgeConfiguration'
import { useAppDetailContext } from '../../AppDetailContext'
import HappyFoxHelpDeskAuthenticateForm from './HappyFoxHelpDeskAuthenticateForm'
// import HappyFoxHelpDeskEmployeeNotifications from './HappyFoxHelpDeskEmployeeNotifications'
import InstallHappyFoxHelpdesk from './InstallHappyFoxHelpdesk'
import ManageHappyFoxHelpdesk from './ManageHappyFoxHelpdesk'

const HelpdeskTabs = ({ app }) => {
  const [isEnableSyncModalOpen, setIsEnableSyncModalOpen] = useState(false)
  const [enableSync, enableSyncResult] = api.useEnableSyncHappyfoxAppMutation()

  const { title } = useAppDetailContext()
  // const showEmployeeNotificationsConfiguration = useFeature([
  //   FEATURE_FLAGS.CONVERSATIONAL_TICKETING,
  //   CLIENT_FEATURE_FLAGS.DM_WORKFLOW_MODE,
  //   CLIENT_FEATURE_FLAGS.SLACK_ACCOUNT
  // ])

  const handleEnableSync = () => {
    if (enableSyncResult.isLoading) return

    const promise = enableSync().unwrap()
    promise.then(() => setIsEnableSyncModalOpen(false)).catch(() => {})
    toast.promise(promise, TOAST_MESSAGES.ENABLE_SYNC(title))
  }

  const isHFSyncEnabled = app.sync_info.is_sync_enabled
  const isHFHelpdeskExpired = app.is_helpdesk_account_expired

  const EnableSyncModalBody = () => {
    return (
      <span className={styles.EnableSyncContainer}>
        {isHFHelpdeskExpired && (
          <Text variant='muted' className={styles.SyncAlert}>
            {CONFIRM_MESSAGES.HF_ACCOUNT_EXPIRED_ALERT(title)}
          </Text>
        )}
        {CONFIRM_MESSAGES.ENABLE_APP_SYNC(title)}
      </span>
    )
  }

  const syncDisabledForAccountExpiryMessage = SYNC_STATUS.DISABLED_FOR_EXPIRY.replace('{{title}}', title).replace(
    '{{account_type}}',
    'helpdesk'
  )

  return (
    <Fragment>
      {!isHFSyncEnabled && !isHFHelpdeskExpired && (
        <SyncDisabledAlert onEnableSync={() => setIsEnableSyncModalOpen(true)} />
      )}
      {!isHFSyncEnabled && isHFHelpdeskExpired && (
        <SyncDisabledAlert
          message={syncDisabledForAccountExpiryMessage}
          onEnableSync={() => setIsEnableSyncModalOpen(true)}
        />
      )}
      <PageTabs pathSensitive>
        <PageTabList>
          <PageTab to='' title='Ticket configuration' />
          <PageTab to='knowledge-configuration' title='Knowledge configuration' />
          {/* {showEmployeeNotificationsConfiguration && (
            <PageTab to='employee-notification' title='Employee Notification' />
          )} */}
        </PageTabList>
        <Routes>
          <Route path='' element={<ManageHappyFoxHelpdesk app={app} />} />
          <Route path='knowledge-configuration' element={<KnowledgeConfiguration app={app} />} />
          {/* <Route
            path='employee-notification'
            element={
              <FeatureProtected
                fallback={<Navigate to='..' replace />}
                group={ROUTE_FEATURE_GROUP.happyfox_helpdesk_employee_notifications}
              >
                <HappyFoxHelpDeskEmployeeNotifications />
              </FeatureProtected>
            }
          /> */}
          <Route path='*' element={<Navigate to='' replace />} />
        </Routes>
      </PageTabs>

      <ConfirmationModal
        isOpen={isEnableSyncModalOpen}
        message={<EnableSyncModalBody />}
        onCancel={() => setIsEnableSyncModalOpen(false)}
        onConfirm={handleEnableSync}
        title='Enable Sync'
      />
    </Fragment>
  )
}

const HappyFoxHelpdesk = () => {
  const { data: app = {}, isFetching } = api.useGetHappyFoxAppQuery()
  const navigate = useNavigate()

  if (isFetching) {
    return <PageLoader />
  }

  if (!app.is_linked) {
    return <InstallHappyFoxHelpdesk app={app} />
  }

  const handleAuthFormCancel = () => {
    navigate('./')
  }

  return (
    <Routes>
      <Route
        path='auth'
        element={
          <HappyFoxHelpDeskAuthenticateForm onCancel={handleAuthFormCancel} onAuth={handleAuthFormCancel} app={app} />
        }
      />
      <Route path='*' element={<HelpdeskTabs app={app} />} />
    </Routes>
  )
}

export default HappyFoxHelpdesk
