// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Yz5wyKbFjnPE5_7wXIXY{display:grid;grid-template-columns:repeat(14, minmax(0, 1fr));background-color:#fff;padding:3rem 2rem;border-radius:var(--border-radius)}.O408A5rtXxmc0NkYbmG2{grid-column:1/11}.oU5LbvOj_hbm3By8teo7{grid-column:12/15;background-color:var(--primary-lightest);display:flex;align-items:center;justify-content:center;padding:1rem}.O408A5rtXxmc0NkYbmG2.PoqzEzcGzhHX37X5gjLt .dgfSpnFDDpdqraseZ0jd{opacity:.7;pointer-events:none}.vi_89X9S9F7HNWAKAtfG{display:flex;align-items:center}.vi_89X9S9F7HNWAKAtfG:not(:last-child){border-bottom:1px solid #f1f0f0}.a7U5CqvL9X0BIkHgGQun{padding:1rem 0}.dgfSpnFDDpdqraseZ0jd{margin-left:auto}", "",{"version":3,"sources":["webpack://./../src/pages/protected/Notifications/Notifications.module.css"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,gDAAA,CACA,qBAAA,CACA,iBAAA,CACA,kCAAA,CAGF,sBACE,gBAAA,CAGF,sBACE,iBAAA,CACA,wCAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,YAAA,CAGF,iEACE,UAAA,CACA,mBAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CAGF,uCACE,+BAAA,CAGF,sBACE,cAAA,CAGF,sBACE,gBAAA","sourcesContent":[".Container {\n  display: grid;\n  grid-template-columns: repeat(14, minmax(0, 1fr));\n  background-color: white;\n  padding: 3rem 2rem;\n  border-radius: var(--border-radius);\n}\n\n.ConfigContainer {\n  grid-column: 1/11;\n}\n\n.Cover {\n  grid-column: 12/15;\n  background-color: var(--primary-lightest);\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding: 1rem;\n}\n\n.ConfigContainer.Loading .Controls{\n  opacity: 0.7;\n  pointer-events: none;\n}\n\n.Config {\n  display: flex;\n  align-items: center;\n}\n\n.Config:not(:last-child) {\n  border-bottom: 1px solid #f1f0f0;\n}\n\n.Content {\n  padding: 1rem 0;\n}\n\n.Controls {\n  margin-left: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Container": "Yz5wyKbFjnPE5_7wXIXY",
	"ConfigContainer": "O408A5rtXxmc0NkYbmG2",
	"Cover": "oU5LbvOj_hbm3By8teo7",
	"Loading": "PoqzEzcGzhHX37X5gjLt",
	"Controls": "dgfSpnFDDpdqraseZ0jd",
	"Config": "vi_89X9S9F7HNWAKAtfG",
	"Content": "a7U5CqvL9X0BIkHgGQun"
};
export default ___CSS_LOADER_EXPORT___;
