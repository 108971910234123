import { MODE_LABEL, MODE_VALUE } from 'Constants/channels'
import { getInitialApiQueryParamsForTable, getInitialParamsForTable } from 'Utils/query-params'

const defaultParams = {
  defaultSearch: '',
  supportedSortColumns: ['name'],
  defaultSortedColumn: 'name',
  defaultSortOrder: 'asc'
}

const getInitialParams = () => {
  return getInitialParamsForTable(defaultParams)
}

const getInitialApiQueryParams = () => {
  const initialParams = getInitialParams()
  return getInitialApiQueryParamsForTable(initialParams)
}

const getModeCellValue = (value) => {
  const { convert_messages, automatic_bot_replies, sync_to_channel_thread } = value

  if (!sync_to_channel_thread) return '-'
  if (convert_messages === MODE_VALUE.AUTOMATIC) return MODE_LABEL.AUTOMATIC
  if (automatic_bot_replies) return MODE_LABEL.MANUAL_WITH_SUGGESTION
  return MODE_LABEL.MANUAL_WITHOUT_SUGGESTION
}

const getModeFormValue = (value) => {
  const { convert_messages, automatic_bot_replies, sync_to_channel_thread } = value

  if (!sync_to_channel_thread) return null
  if (convert_messages === MODE_VALUE.AUTOMATIC) return MODE_VALUE.AUTOMATIC
  if (automatic_bot_replies) return MODE_VALUE.MANUAL_WITH_SUGGESTION
  return MODE_VALUE.MANUAL_WITHOUT_SUGGESTION
}

const getEditChannelPayload = (value, options) => {
  const { isHfHelpdeskTicketingConnected, isHfServicedeskTicketingConnected } = options

  const {
    syncToChannelThread,
    mode,
    // for HD
    allowedCategories,
    defaultCategoryForManualMode,
    defaultCategoryForAutoMode,
    // for SD
    allowedTeams,
    defaultTeamForManualMode,
    defaultTeamForAutoMode
  } = value

  const payload = {
    sync_to_channel_thread: syncToChannelThread
  }

  if (syncToChannelThread) {
    const convertMessages = mode === MODE_VALUE.AUTOMATIC ? MODE_VALUE.AUTOMATIC : MODE_VALUE.MANUAL
    payload.convert_messages = convertMessages

    if (convertMessages === MODE_VALUE.MANUAL) {
      payload.automatic_bot_replies = mode === MODE_VALUE.MANUAL_WITH_SUGGESTION

      if (isHfHelpdeskTicketingConnected) {
        payload.default_category_id = defaultCategoryForManualMode?.id
        payload.allowed_category_ids = allowedCategories.map((category) => category.id)
      }

      if (isHfServicedeskTicketingConnected) {
        payload.default_team_id = defaultTeamForManualMode?.id
        payload.allowed_team_ids = allowedTeams.map((team) => team.id)
      }
    } else {
      if (isHfHelpdeskTicketingConnected) {
        payload.default_category_id = defaultCategoryForAutoMode.id
      }
      if (isHfServicedeskTicketingConnected) {
        payload.default_team_id = defaultTeamForAutoMode.id
      }
    }
  }

  return payload
}

export { getModeCellValue, getModeFormValue, getEditChannelPayload }
export { getInitialParams, getInitialApiQueryParams }
