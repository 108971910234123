import {
  Heading,
  Table,
  TableBody,
  TableHead,
  TableOption,
  TableOptions,
  TableRow,
  Td,
  Text,
  Th
} from '@happyfoxinc/react-ui'
import { Fragment, useCallback, useMemo, useState } from 'react'
import toast from 'react-hot-toast'
import { useTable } from 'react-table'

import styles from './CollectionsConfig.module.css'

import { SecondaryButton } from 'Components/Buttons'
import ConfirmationModal from 'Components/ConfirmationModal'
import ReactSelect from 'Components/ReactSelect'
import api from 'Services/api'
import PageLoader from 'Src/components/PageLoader'
import parseErrorMessage from 'Utils/error-message-parser'

const CollectionsTable = ({ data, onDelete }) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [collectionToDelete, setCollectionToDelete] = useState(null)

  const columns = useMemo(() => {
    return [
      {
        Header: 'Collections',
        accessor: 'collection_name'
      }
    ]
  }, [])

  const { getTableProps, headerGroups, getTableBodyProps, rows, prepareRow } = useTable({
    columns,
    data
  })

  const openDeleteModal = (id) => {
    setCollectionToDelete(id)
    setShowDeleteModal(true)
  }

  const handleRemoveCollection = useCallback(() => {
    onDelete(collectionToDelete)
    setShowDeleteModal(false)
    setCollectionToDelete(null)
  }, [collectionToDelete, onDelete])

  return (
    <Fragment>
      <Table className={styles.CollectionsTable} {...getTableProps()}>
        <TableHead>
          {headerGroups.map((headerGroup) => {
            const { key, ...headerGroupProps } = headerGroup.getHeaderGroupProps()

            return (
              <TableRow key={key} {...headerGroupProps} style={{ backgroundColor: '#EEEEEE' }}>
                <Fragment>
                  {headerGroup.headers.map((column) => {
                    const { key, ...headerProps } = column.getHeaderProps()

                    return (
                      <Th key={key} {...headerProps} className={styles.TableCell}>
                        <Text variant='muted'>{column.render('Header')} </Text>
                      </Th>
                    )
                  })}
                  <Th isOption />
                </Fragment>
              </TableRow>
            )
          })}
        </TableHead>

        <TableBody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row)
            const { key, ...rowProps } = row.getRowProps()

            return (
              <TableRow key={key} {...rowProps} clickable>
                {row.cells.map((cell) => {
                  const { key, ...cellProps } = cell.getCellProps()
                  return (
                    <Td className={styles.TableCell} key={key} {...cellProps}>
                      {cell.render('Cell')}
                    </Td>
                  )
                })}
                <Td className={styles.TableCell}>
                  <TableOptions>
                    <TableOption onClick={() => openDeleteModal(row.original.collection_id)}>Delete</TableOption>
                  </TableOptions>
                </Td>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
      <ConfirmationModal
        isOpen={showDeleteModal}
        variant='alert'
        message='Are you sure you want to delete this collection?'
        onCancel={() => setShowDeleteModal(false)}
        onConfirm={handleRemoveCollection}
      />
    </Fragment>
  )
}

const CollectionsConfig = () => {
  const [selectedCollections, setSelectedCollections] = useState([])
  const { data: collections = [], isLoading: isCollectionsLoading } = api.useGetCollectionsQuery()
  const { data: syncedCollectionsApiResponse = {}, isLoading: isSyncedCollectionsLoading } =
    api.useGetSyncedCollectionsQuery()
  const { results: syncedCollections = [] } = syncedCollectionsApiResponse
  const [addCollections, addCollectionsResult] = api.useAddCollectionsMutation()
  const [removeCollection] = api.useRemoveSyncedCollectionMutation()

  const handleAddCollections = useCallback(() => {
    const collectionIds = selectedCollections.map((collection) => collection.id)
    const promise = addCollections({ collection_ids: collectionIds }).unwrap()
    promise.then(() => setSelectedCollections([]))

    toast.promise(promise, {
      loading: 'Adding collections',
      success: 'Collections added successfully',
      error: 'Unable to add collections. Try again'
    })
  }, [addCollections, selectedCollections])

  const handleRemoveCollection = useCallback(
    async (id) => {
      try {
        await removeCollection(id).unwrap()
        toast.success('Collection deleted successfully.')
      } catch (error) {
        toast.error(parseErrorMessage('Unable to delete collection. Try again...'))
      }
    },
    [removeCollection]
  )

  if (isCollectionsLoading || isSyncedCollectionsLoading) {
    return <PageLoader />
  }

  const disableAddButton = selectedCollections.length === 0 || addCollectionsResult.isLoading

  return (
    <div className={styles.Container}>
      <Text>Add collection Ids to sync from</Text>
      <div className={styles.SelectCollectionsContainer}>
        <ReactSelect
          isMulti
          value={selectedCollections}
          onChange={setSelectedCollections}
          styles={{
            control: (provided) => ({
              ...provided,
              width: '300px'
            })
          }}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.id}
          options={collections}
          placeholder='Select collections'
        />
        <SecondaryButton onClick={handleAddCollections} className={styles.AddButton} disabled={disableAddButton}>
          Add +
        </SecondaryButton>
      </div>
      <div className={styles.SyncedCollectionsContainer}>
        <Heading level={6}>Collections currently syncing from</Heading>
        <CollectionsTable data={syncedCollections} onDelete={handleRemoveCollection} />
      </div>
    </div>
  )
}

export default CollectionsConfig
