import { Button, Heading } from '@happyfoxinc/react-ui'
import { Fragment } from 'react'

import styles from './InstallApp.module.css'

import { SecondaryButton } from 'Components/Buttons'
import { useFeature } from 'Components/Features'
import FEATURE_FLAGS from 'Constants/feature-flags'
import { URLS } from 'Constants/urls'
import api from 'Services/api'
import { openUrl } from 'Utils/url'

import { useAppDetailContext } from '../../AppDetails/AppDetailContext'
import LinkIcon from './link.svg'
import LinkedIcon from './linked.svg'

export const InstallAppBase = ({ children, ...rest }) => {
  return (
    <div className={styles.Container} {...rest}>
      <LinkedIcon height='3em' width='3em' />
      {children}
    </div>
  )
}

const UpgradeButtons = () => {
  return (
    <div className={styles.PlanButtonGroup}>
      <Button variant='primary' onClick={() => openUrl(URLS.ASSIST_AI)}>
        Upgrade Plan
      </Button>
      <SecondaryButton onClick={() => openUrl(URLS.ASSIST_AI)}>Plan comparison</SecondaryButton>
    </div>
  )
}

const InstallApp = ({ name, onInstall, isInstalling = false }) => {
  const { data: account } = api.useGetAccountQuery()
  const { planCategory } = useAppDetailContext()

  const isPremiumAppsEnabled = useFeature([FEATURE_FLAGS.PREMIUM_INTEGRATIONS])

  if (planCategory === 'premium' && !isPremiumAppsEnabled) {
    return (
      <div className={styles.Container}>
        <div>
          <Heading level={2}>Your current plan does not support this integration</Heading>
          <Heading level={2} variant='muted'>
            Upgrade your plan to connect.
          </Heading>
        </div>
        <UpgradeButtons />
      </div>
    )
  }

  if (account.is_integrations_limit_reached) {
    return (
      <div className={styles.Container}>
        <div>
          <Heading level={2}>You have reached your Integration Limit.</Heading>
          <Heading level={2} variant='muted'>
            Upgrade your plan to connect more integrations.
          </Heading>
        </div>
        <UpgradeButtons />
      </div>
    )
  }

  return (
    <div className={styles.Container}>
      <LinkIcon height='3em' width='3em' />
      <Fragment>
        <Heading level={2}>
          You haven't linked your {name} account yet,
          <br />
          click below to begin the process.
        </Heading>
        <Button variant='primary' onClick={onInstall} disabled={isInstalling}>
          Install {name}
        </Button>
      </Fragment>
    </div>
  )
}

export default InstallApp
