import AzureADLogo from 'Images/brands/azure-ad.svg'
import OktaLogo from 'Images/brands/okta.svg'

import keyMap from 'Constants/key-map'

const { OKTA, AZURE_AD } = keyMap

export const KNOWLEDGE_GROUP_VISIBILITY_STATES = {
  ALL: 'all_users',
  SAME_AS_PARENT: 'same_as_parent',
  SPECIFIC: 'specific_user_groups',
  NONE: 'no_users'
}

export const KNOWLEDGE_GROUP_VISIBILITY_OPTIONS = [
  { label: 'All Users', value: KNOWLEDGE_GROUP_VISIBILITY_STATES.ALL },
  { label: 'Same as Parent', value: KNOWLEDGE_GROUP_VISIBILITY_STATES.SAME_AS_PARENT },
  { label: 'Specific User group(s)', value: KNOWLEDGE_GROUP_VISIBILITY_STATES.SPECIFIC },
  { label: 'No Users', value: KNOWLEDGE_GROUP_VISIBILITY_STATES.NONE }
]

export const ANSWER_VISIBILITY_OPTIONS = [
  { label: 'All Users', value: KNOWLEDGE_GROUP_VISIBILITY_STATES.ALL },
  { label: 'Specific User group(s)', value: KNOWLEDGE_GROUP_VISIBILITY_STATES.SPECIFIC },
  { label: 'No Users', value: KNOWLEDGE_GROUP_VISIBILITY_STATES.NONE }
]

export const WORKSPACE_VISIBILITY_OPTIONS = [
  { label: 'All Users', value: KNOWLEDGE_GROUP_VISIBILITY_STATES.ALL },
  { label: 'Specific User group(s)', value: KNOWLEDGE_GROUP_VISIBILITY_STATES.SPECIFIC }
]

export const USERGROUP_DELETE_MESSAGE = {
  KB_ASSOCIATED: 'This user group is associated with one or more knowledge source. Are you sure you want to proceed?',
  NO_KB_ASSOCIATED: 'Are you sure to delete this user group?'
}

export const USER_MANAGEMENT_TYPE = {
  MANUAL: 'manual',
  SYNCED_FROM_APP: 'synced_from_app'
}

export const APP_SYNCED_FROM = {
  OKTA: OKTA.KEY,
  AZURE_AD: AZURE_AD.KEY
}

export const APP_SYNCED_FROM_DEFINITIONS = {
  [USER_MANAGEMENT_TYPE.MANUAL]: {
    label: 'Add Users Manually',
    value: USER_MANAGEMENT_TYPE.MANUAL
  },
  [APP_SYNCED_FROM.OKTA]: {
    label: 'Okta',
    value: APP_SYNCED_FROM.OKTA,
    logo: OktaLogo
  },
  [APP_SYNCED_FROM.AZURE_AD]: {
    label: 'Azure Active Directory',
    value: APP_SYNCED_FROM.AZURE_AD,
    logo: AzureADLogo
  }
}

export const SLACK_ID_KEY = 'slack_user_id'
export const MS_TEAMS_ID_KEY = 'azure_ad_user_id'
export const APP_NOT_INSTALLED_MSG = 'Assist AI is not installed by the user'
