import { Button, Flex, FormControl, FormErrorText, Input, Label, Text } from '@happyfoxinc/react-ui'
import { yupResolver } from '@hookform/resolvers/yup'
import { Fragment } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'

import styles from './EditChannelModal.module.scss'

import ReactSelect from 'Components/ReactSelect'
import { AUTOMATIC, MODE_OPTIONS, SYNC_TO_CHANNEL_OPTIONS } from 'Constants/channels'
import { TICKETING_INTEGRATION } from 'Constants/ticketingIntegration'
import Sheet from 'Layout/Sheet'
import api from 'Services/api'
import { getEditChannelPayload, getModeFormValue } from 'Src/pages/protected/Channels/channels-helper'
import parseErrorMessage from 'Utils/error-message-parser'

import { HelpDeskFields, ServiceDeskFields } from './TicketingSpecificFields'
import getValidationSchema from './validationSchema'

const EditChannelModal = ({ onHide, ...channelData }) => {
  const { data: account } = api.useGetAccountQuery()
  const isHfHelpdeskTicketingConnected = account.connected_ticketing_integration === TICKETING_INTEGRATION.HF_HELP_DESK
  const isHfServicedeskTicketingConnected =
    account.connected_ticketing_integration === TICKETING_INTEGRATION.HF_SERVICE_DESK

  const {
    channel_id,
    name,
    sync_to_channel_thread,
    convert_messages,
    // for HD
    default_category,
    allowed_categories,
    // for SD
    default_team,
    allowed_teams
  } = channelData

  const isAutoMode = sync_to_channel_thread && convert_messages === AUTOMATIC
  const isManualMode = sync_to_channel_thread && convert_messages !== AUTOMATIC

  const formMethods = useForm({
    defaultValues: {
      syncToChannelThread: sync_to_channel_thread,
      mode: getModeFormValue(channelData),

      ...(isHfHelpdeskTicketingConnected && {
        defaultCategoryForAutoMode: isAutoMode ? default_category : null,
        defaultCategoryForManualMode: isManualMode ? default_category : null,
        allowedCategories: allowed_categories
      }),

      ...(isHfServicedeskTicketingConnected && {
        defaultTeamForAutoMode: isAutoMode ? default_team : null,
        defaultTeamForManualMode: isManualMode ? default_team : null,
        allowedTeams: allowed_teams
      })
    },
    resolver: yupResolver(getValidationSchema(isHfHelpdeskTicketingConnected))
  })

  const {
    formState: { errors, isDirty },
    handleSubmit,
    control,
    watch
  } = formMethods

  const showModeSelection = watch('syncToChannelThread')

  const [editSlackChannel, editSlackChannelResult] = api.useEditSlackChannelMutation()

  const handleFormSubmit = (value) => {
    const payload = getEditChannelPayload(value, {
      isHfHelpdeskTicketingConnected,
      isHfServicedeskTicketingConnected
    })
    const promise = editSlackChannel({ channelId: channel_id, ...payload }).unwrap()

    toast.promise(promise, {
      loading: 'Updating the channel configuration',
      success: 'Channel configuration updated successfully',
      error: parseErrorMessage('Unable to update the channel configuration. Try again...')
    })

    promise.then(onHide).catch(() => {})
  }

  const shouldDisableSave = !isDirty || editSlackChannelResult.isLoading

  const formatOptionLabel = ({ label, subLabel }, { context }) => {
    if (context === 'menu') {
      return (
        <Fragment>
          <Text>{label}</Text>
          <Text variant='muted' size='xs'>
            {subLabel}
          </Text>
        </Fragment>
      )
    }

    return label
  }

  return (
    <Sheet title='Edit Channel' onHide={onHide}>
      <FormProvider {...formMethods}>
        <form className={styles.FormContainer} onSubmit={handleSubmit(handleFormSubmit)}>
          <FormControl>
            <Label>Channel Name</Label>
            <Input defaultValue={name} disabled />
          </FormControl>

          <FormControl isInvalid={errors.syncToChannelThread}>
            <Label>Should Assist AI post ticket info & updates publicly in the channel ?</Label>
            <Controller
              control={control}
              name='syncToChannelThread'
              render={({ field }) => {
                return (
                  <ReactSelect
                    {...field}
                    isSearchable={false}
                    options={SYNC_TO_CHANNEL_OPTIONS}
                    value={SYNC_TO_CHANNEL_OPTIONS.find(({ value }) => field.value === value)}
                    onChange={({ value }) => field.onChange(value)}
                    getOptionLabel={(option) => option.label}
                    getOptionValue={(option) => option.value}
                    formatOptionLabel={formatOptionLabel}
                  />
                )
              }}
            />
            {errors.syncToChannelThread && <FormErrorText>{errors.syncToChannelThread.message}</FormErrorText>}
          </FormControl>

          {showModeSelection && (
            <FormControl isInvalid={errors.mode}>
              <Label>How do you want Assist AI to create tickets ?</Label>
              <Controller
                name='mode'
                control={control}
                render={({ field }) => {
                  return (
                    <ReactSelect
                      {...field}
                      isSearchable={false}
                      options={MODE_OPTIONS}
                      value={MODE_OPTIONS.find(({ value }) => field.value === value)}
                      onChange={({ value }) => field.onChange(value)}
                      getOptionLabel={(option) => option.label}
                      getOptionValue={(option) => option.value}
                      formatOptionLabel={formatOptionLabel}
                    />
                  )
                }}
              />
              {errors.mode && <FormErrorText>{errors.mode.message}</FormErrorText>}
            </FormControl>
          )}

          {isHfHelpdeskTicketingConnected && <HelpDeskFields />}
          {isHfServicedeskTicketingConnected && <ServiceDeskFields />}

          <Flex className={styles.ButtonContainer}>
            <Button variant='primary' disabled={shouldDisableSave} type='submit'>
              Save
            </Button>
            <Button onClick={onHide} variant='link-muted'>
              Cancel
            </Button>
          </Flex>
        </form>
      </FormProvider>
    </Sheet>
  )
}

export default EditChannelModal
