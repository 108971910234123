import { Button, FormControl, FormErrorText, Heading, Input, Label } from '@happyfoxinc/react-ui'
import { yupResolver } from '@hookform/resolvers/yup'
import React, { Fragment } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'

import { useRedirectConfirmationContext } from 'Components/ConfirmationModal/RedirectConfirmationContext'
import api from 'Services/api'
import parseErrorMessage from 'Utils/error-message-parser'

import { useAppDetailContext } from '../../AppDetailContext'
import happyfoxHelpdeskAuthFormValidationSchema from './happyfox-helpdesk-auth-form-validation-schema'

const HappyFoxHelpDeskAuthenticateForm = (props) => {
  const { onCancel, app } = props
  const { title } = useAppDetailContext()
  const [authorize, authorizeResult] = api.useAuthorizeHappyFoxAppMutation()
  const externalRedirect = useRedirectConfirmationContext()

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors }
  } = useForm({
    defaultValues: {
      accountUrl: app.account_url || ''
    },
    resolver: yupResolver(happyfoxHelpdeskAuthFormValidationSchema)
  })

  const handleConnect = async (data) => {
    const payload = {
      account_url: data.accountUrl
    }

    const toastId = toast.loading(`Connecting to ${title}`)

    try {
      const authDetails = await authorize(payload).unwrap()
      toast.dismiss(toastId)
      externalRedirect(authDetails.authorize_url)
    } catch (err) {
      toast.error(parseErrorMessage(`Unable to connect to ${title}. Try again...`)(err), { id: toastId })
    }
  }

  const disableSubmitButton = isSubmitting || authorizeResult.isLoading

  return (
    <Fragment>
      <Heading level={2}>Connect to {title}</Heading>
      <form onSubmit={handleSubmit(handleConnect)}>
        <FormControl isInvalid={errors.accountUrl}>
          <Label>HappyFox Helpdesk account URL</Label>
          <Input placeholder='https://myaccount.happyfox.com' {...register('accountUrl')} />
          {errors.accountUrl && <FormErrorText>{errors.accountUrl.message}</FormErrorText>}
        </FormControl>
        <Button variant='primary' type='submit' disabled={disableSubmitButton}>
          Authorize
        </Button>
        <Button variant='link-muted' onClick={onCancel}>
          Cancel
        </Button>
      </form>
    </Fragment>
  )
}

export default HappyFoxHelpDeskAuthenticateForm
