import { Label, Text } from '@happyfoxinc/react-ui'
import cx from 'classnames'
import { Fragment } from 'react'

import styles from './SyncedUsersStatus.module.scss'

import AlertIcon from 'Icons/alert.svg'
import Reload from 'Icons/reload.svg'
import UserIcon from 'Icons/user.svg'

import { ACCOUNT_TYPE } from 'Constants/account'
import api from 'Services/api'
import InProgressCard from 'Components/InProgressCard'

const FailedSyncMessage = ({ count }) => {
  const { data: account = {} } = api.useGetAccountQuery()
  const isSlackAccountType = account.account_type === ACCOUNT_TYPE.SLACK
  const isMsTeamsAccountType = account.account_type === ACCOUNT_TYPE.MS_TEAMS

  const getMessage = () => {
    if (isSlackAccountType) {
      return `They may not be a member of Slack workspace.`
    }

    if (isMsTeamsAccountType) {
      return `They may not be a member of Teams workspace, or they may not have Assist AI installed on Teams yet.`
    }
  }

  return (
    <div className={styles.AlertMessageContainer}>
      <div className={styles.AlertIcon}>
        <AlertIcon />
      </div>
      <div>
        <Text isInline variant='default'>
          {`${count} ${count === 1 ? 'user' : 'users'} could not be imported. `}
        </Text>
        <Text isInline variant='muted'>
          {getMessage()}
        </Text>
      </div>
    </div>
  )
}

const SyncedUsersStatus = ({ userGroup, isRefreshing, onReSync, onRefresh }) => {
  const {
    is_syncing: isAppSyncing,
    users_count: usersCount,
    sync_from_group: syncedFromGroup,
    sync_failed_users_count: syncFailedUsersCount
  } = userGroup

  const showFailedSyncMessage = !isAppSyncing && syncFailedUsersCount > 0

  return (
    <Fragment>
      <div>
        <Label>Users</Label>
        {isAppSyncing && (
          <InProgressCard isRefreshing={isRefreshing} onRefresh={onRefresh}>
            <UserIcon className={styles.UsersIcon} />
            <div> We are syncing your users now</div>
            <Text variant='muted'>It'll be ready in a bit. Please come back later.</Text>
          </InProgressCard>
        )}
        {!isAppSyncing && (
          <div className={styles.UsersContainer}>
            <div className={styles.SyncedUsersDetails}>
              <div className={styles.SyncedUsersCountBadge}>
                <div className={styles.SyncedUsersCountNo}>{usersCount}</div>
                <div className={styles.SyncedUsersCountText}>Users</div>
              </div>
              <div className={styles.SyncedUserFrom}>
                <Text isInline variant='muted'>
                  Imported from{' '}
                </Text>
                {syncedFromGroup?.name}
              </div>
              <button type='button' onClick={onReSync} className={cx(styles.ActionButton, styles.ReSyncButton)}>
                Re-sync <Reload />
              </button>
            </div>
          </div>
        )}
      </div>
      {showFailedSyncMessage && <FailedSyncMessage count={syncFailedUsersCount} />}
    </Fragment>
  )
}

export default SyncedUsersStatus
