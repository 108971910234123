import styles from './InProgressCard.module.scss'
import PageLoader from 'Components/PageLoader'
import Reload from 'Icons/reload.svg'

const InProgressCard = ({ children, isRefreshing, onRefresh }) => {
  return (
    <div className={styles.OuterContainer}>
      <div className={styles.Container}>
        <div className={styles.Outline}>
          <div className={styles.Content}>{children}</div>
          <div className={styles.RefreshContainer}>
            {isRefreshing && <PageLoader size='sm' />}
            {!isRefreshing && (
              <button type='button' onClick={onRefresh} className={styles.ActionButton}>
                Refresh <Reload />
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default InProgressCard
