import { FormControl, FormErrorText, Label } from '@happyfoxinc/react-ui'
import { Fragment, useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import styles from './UserGroupForm.module.css'

import NoRender from 'Components/NoRender'
import ReactSelect from 'Components/ReactSelect'
import { APP_SYNCED_FROM_DEFINITIONS, USER_MANAGEMENT_TYPE } from 'Constants/user-groups'
import api from 'Services/api'

const { MANUAL, SYNCED_FROM_APP } = USER_MANAGEMENT_TYPE

const FormatGroupLabel = ({ type }) =>
  type === SYNCED_FROM_APP && (
    <Fragment>
      <div className={styles.AddedFromGroupTitleOr}>or</div>
      <div className={styles.AddedFromGroupTitleSync}>Sync From</div>
    </Fragment>
  )

const FormatOptionLabel = ({ label, logo }) => {
  const Logo = logo || NoRender
  return (
    <div className={styles.AddedFromOptionContainer}>
      {logo && (
        <div className={styles.AddedFromLogoContainer}>
          <Logo />
        </div>
      )}
      <div>{label}</div>
    </div>
  )
}

const AddedFromField = ({ onChange }) => {
  const {
    control,
    formState: { errors }
  } = useFormContext()

  const { data: optionsMetaData = {}, isLoading: isOptionsLoading } = api.useGetUserGroupMetaInfoQuery()

  const options = useMemo(() => {
    const { available_user_management_types: availableTypes, apps_available_to_sync_from: availableApps } =
      optionsMetaData

    if (!availableTypes) {
      return []
    }

    return availableTypes.map((type) => {
      if (type === MANUAL) {
        return {
          type: MANUAL,
          options: [APP_SYNCED_FROM_DEFINITIONS[MANUAL]]
        }
      }
      return {
        type: SYNCED_FROM_APP,
        options: availableApps.map((app) => APP_SYNCED_FROM_DEFINITIONS[app])
      }
    })
  }, [optionsMetaData])

  const customStyles = {
    valueContainer: (provided, { selectProps: { value } }) => ({
      ...provided,
      ...(value && value.value !== MANUAL && { paddingLeft: '1px' })
    }),
    option: (provided, { isFocused, isSelected }) => ({
      ...provided,
      backgroundColor: isFocused || isSelected ? '#F9F9F9' : '#FFFFFF',
      color: '#000000'
    }),
    group: (provided, { data: { type } }) => ({
      ...provided,
      ...(type === MANUAL && { paddingTop: '0', paddingBottom: '0' }),
      ...(type !== MANUAL && { paddingTop: '5px' })
    }),
    groupHeading: (provided) => ({
      ...provided,
      textTransform: 'auto',
      fontSize: '13px'
    })
  }

  return (
    <FormControl className={styles.AddedFromField} isInvalid={errors.addedFrom}>
      <Label>How do you want to add users?</Label>
      <Controller
        name='addedFrom'
        control={control}
        render={({ field }) => {
          return (
            <ReactSelect
              {...field}
              styles={customStyles}
              isSearchable={false}
              onChange={(opt) => {
                field.onChange(opt)
                onChange()
              }}
              options={options}
              formatGroupLabel={FormatGroupLabel}
              formatOptionLabel={FormatOptionLabel}
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
              isLoading={isOptionsLoading}
            />
          )
        }}
      />
      {errors.addedFrom && <FormErrorText>{errors.addedFrom.message}</FormErrorText>}
    </FormControl>
  )
}

export default AddedFromField
